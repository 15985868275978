<template>
  <div class="uk-container uk-form-width-large">
    <vue-headful
      description="claim FOREX"
      image="src/assets/logo.png"
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | claim FOREX`"
    />

    <div class="claim">
      <h2 class="uk-h2 uk-margin-remove-bottom">claim FOREX</h2>

      <form novalidate autocomplete="off" class="uk-margin-small-top">
        <fieldset class="uk-fieldset uk-width-1-1">
          <div class="uk-margin-small-top">
            <div v-if="!account">
              please connect your wallet to see your claim stats
            </div>
            <div v-if="account && loadingData">loading...</div>
            <div v-if="account && !loadingData">
              <div>
                claimable $FOREX: {{ formatEther(currentClaimableBalance) }}
              </div>
              <div
                class="uk-margin-small-top"
                v-if="claimableContractCount === 0"
              >
                you cannot claim your funds yet, please check again later
              </div>
              <div
                class="uk-margin-small-top"
                v-if="claimableContractCount > 1"
              >
                you will execute a total of
                {{ claimableContractCount }} transactions to claim all your
                $FOREX
              </div>
              <div class="uk-margin-small-top">
                <label
                  class="uk-form-label hfi-form-label-width-medium"
                  for="claimButton"
                ></label>
                <div class="uk-form-controls">
                  <button
                    id="claimButton"
                    class="uk-button uk-button-primary uk-width-expand hfi-button"
                    @click="claim"
                    type="button"
                    :disabled="!canTransact"
                  >
                    {{ this.transactionButtonText }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import Token from "../types/Token";
import { store } from "@/store";
import { addEventListener, removeEventListener } from "../utils/event";
import { signer } from "@/utils/wallet";
import Event from "../types/Event";
import { ethers } from "ethers";
import ForexVestingAbi from "@/abi/ForexVesting";
import { listenForConfirmations } from "../contracts/utils/sendTransaction";
import { showNotification } from "@/utils/utils";
import formatError from "@/utils/formatError";
import config from "../contracts.config.json";

export default {
  name: "Claim",
  data() {
    return {
      processingTransaction: false,
      loadingData: true,
      vestingContracts: {},
      walletUpdateEventId: undefined,
      /** @type {[{
       *    vestingName: string,
       *    vestingPeriodSeconds: number,
       *    vestingPeriodEndDate: number | null,
       *    lastClaimDate: number,
       *    vestedValue: ethers.BigNumber,
       *    currentlyClaimable: ethers.BigNumber,
       *    initiallyClaimable: ethers.BigNumber,
       *    claimStartDate: number,
       *    nextClaimDate: number,
       *    claimDelay: number,
       *    isClaimable: boolean,
       *    contractAddress: string,
       *  }]}
       */
      vestingData: [],
      /** @type {{
       *    strategic: ethers.Contract,
       *    seed: ethers.Contract,
       *    preseed: ethers.Contract,
       * }}
       */
      contracts: {},
      oneEth: ethers.utils.parseEther("1"),
      Token,
      ethers,
    };
  },
  computed: {
    account() {
      return store.state.account;
    },
    network() {
      return store.state.network;
    },
    transactionButtonText() {
      if (this.processingTransaction) return "processing...";
      return `claim ${this.formatEther(this.currentClaimableBalance)} $FOREX`;
    },
    canTransact() {
      return (
        !this.wrongNetwork &&
        this.account &&
        !this.processingTransaction &&
        this.claimableContractCount > 0 &&
        this.currentClaimableBalance.gt(0)
      );
    },
    currentClaimableBalance() {
      return this.vestingData.reduce(
        (a, b) => a.add(b.currentlyClaimable),
        ethers.constants.Zero
      );
    },
    claimablePlusAccruedBalance() {
      return this.vestingData.reduce(
        (a, b) => a.add(b.claimablePlusAccrued),
        ethers.constants.Zero
      );
    },
    forexRatePerSecond() {
      return this.vestingData.reduce(
        (a, b) => a.add(b.vestedValue.div(b.vestingPeriodSeconds)),
        ethers.constants.Zero
      );
    },
    claimableContractCount() {
      return this.vestingData.reduce(
        (a, b) => (b.isClaimable && b.currentlyClaimable.gt(0) ? a + 1 : a),
        0
      );
    },
    isLite() {
      return store.state.isLite;
    },
    wrongNetwork() {
      return (
        this.$route.meta?.networks &&
        !this.$route.meta?.networks.includes(this.network)
      );
    },
  },
  mounted() {
    this.initialiseState();
    window.setvestingContracts = (object) => {
      this.loadData(object);
    };
  },
  beforeDestroy() {
    removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
  },
  methods: {
    formatEther(value, digits = 2) {
      return parseFloat(ethers.utils.formatEther(value)).toLocaleString(
        undefined,
        this.digits(digits)
      );
    },
    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
    initialiseState: async function () {
      this.processingTransaction = true;
      removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
      this.walletUpdateEventId = addEventListener(
        Event.WalletUpdate,
        this.resetState.bind(this)
      );
      await this.resetState();
      this.processingTransaction = false;
    },
    resetState: async function () {
      this.vestingContracts = config[store.state.network]?.vesting ?? {};
      await this.loadData();
    },
    loadData: async function (contracts) {
      if (!this.account) return;
      contracts = contracts || this.vestingContracts;
      this.loadingData = true;
      this.loadContracts(contracts, signer);
      await this.processVestingData(contracts);
      this.loadingData = false;
    },
    loadContracts: function (vestingContracts, signer) {
      this.contracts = {};
      for (let key in vestingContracts) {
        this.contracts[key] = new ethers.Contract(
          vestingContracts[key],
          ForexVestingAbi,
          signer
        );
      }
    },
    processVestingData: async function (vestingContracts) {
      const promises = [];
      for (let key in this.contracts)
        promises.push(this.fetchVestingData(this.contracts[key]));
      const vestingArray = await Promise.all(promises);
      const vestingContractKeys = Object.keys(vestingContracts);
      this.vestingData = vestingArray
        .map((data, i) => {
          if (data == null) return null;
          return {
            ...data,
            vestingName: vestingContractKeys[i],
            vestingPeriodEndDate:
              data.claimStartDate > 0
                ? data.claimStartDate + data.vestingPeriodSeconds * 1000 + 1000
                : null,
            isClaimable:
              data.claimStartDate > 0 && Date.now() >= data.nextClaimDate,
            contractAddress: vestingContracts[vestingContractKeys[i]],
          };
        })
        .filter((x) => x != null);
    },
    fetchVestingData: async function (contract) {
      let participant;
      try {
        participant = await contract.participants(this.account);
      } catch (error) {
        console.error(
          `Could not fetch data from contract at address "${contract.address}"`,
          error
        );
        return null;
      }
      const claimStartDate =
        (await contract.claimStartDate()).toNumber() * 1000;
      const lastClaimDate = participant.lastClaimDate.toNumber() * 1000;
      const [
        claimDelaySeconds,
        lastCutoffTime,
        claimablePlusAccrued,
        vestingPeriodSeconds,
      ] = await Promise.all([
        contract.minimumClaimDelay(),
        contract.getLastCutoffTime(this.account),
        contract.balanceOf(this.account),
        contract.vestingPeriod(),
      ]);
      return {
        vestedValue: participant.vestedValue,
        claimablePlusAccrued,
        currentlyClaimable:
          claimStartDate > 0 && Date.now() >= claimStartDate
            ? await contract._balanceOf(this.account, lastCutoffTime)
            : ethers.constants.Zero,
        initiallyClaimable: participant.claimable,
        lastClaimDate,
        vestingPeriodSeconds: vestingPeriodSeconds.toNumber(),
        claimStartDate,
        claimDelaySeconds: claimDelaySeconds.toNumber(),
        nextClaimDate: lastClaimDate + claimDelaySeconds.toNumber() * 1000,
      };
    },
    claim: async function () {
      let i = 0;
      this.processingTransaction = true;
      for (let key in this.contracts) {
        const contract = this.contracts[key];
        // Check if contract is claimable.
        const data = this.vestingData.find(
          (x) => x.contractAddress === contract.address
        );
        if (!data.isClaimable || data.currentlyClaimable.lte(0)) continue;
        // Claim.
        const tx = await contract.claim();
        try {
          const progressString = `[${i + 1}/${this.claimableContractCount}]`;
          await listenForConfirmations(
            tx,
            `follow wallet instructions to confirm your claim ${progressString}`,
            undefined,
            async (transaction, etherscanMessage) => {
              return `Successfully claimed $FOREX ${progressString} ${etherscanMessage}`;
            },
            1
          );
        } catch (error) {
          console.error(error);
          showNotification("error", (await formatError(error)).toLowerCase());
        }
        i++;
      }
      await this.resetState();
      this.processingTransaction = false;
    },
  },
};
</script>
